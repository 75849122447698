header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1002;
    background-color: var(--vz-header-bg);
    transition: all 0.1s ease-out;
    border-bottom: 1px solid var(--vz-header-border);
}

footer {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 0px 15px;
    z-index: 1002;
    background-color: var(--vz-header-bg);
    transition: all 0.1s ease-out;
    border-top: 1px solid var(--vz-header-border);
}

.mapbook_address {
    max-width: 280px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 991.98px) {
    .mapbook_address {
        max-width: 220px;
    }
}

@media (max-width: 575.98px) {
    .mapbook_address {
        max-width: 180px;
    }
}

.form-control.custom-input {
    border-radius: 8px;
    border: 2px solid #70ebb5;
    background-color: #607d8b;
    color: #fff;
    font-size: 14px;
    padding-right: 40px;
}

.form-control.custom-input::-moz-placeholder {
    color: #e1e1e1
}

.form-control.custom-input::placeholder {
    color: #e1e1e1
}

.navigation-history .item {
    position: relative;
    color: #fff;
    list-style: none;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 8px 10px;
    border-radius: 4px;
    margin-bottom: 8px;
}

.navigation-history {
    padding: 0;
    margin: 0;
    max-height: 400px;
    overflow-y: auto;
}

.navigation-history .item .title {
    padding: 0 26px 0 0;
    margin: 0;
    font-size: 14px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #fff;
    display: block;
}

.navigation-history .item .btn-remove {
    color: #fff;
    font-size: 14px;
}

.no-item {
    color: #ddd;
    text-align: center;
    font-size: 16px;
    padding: 20px;
}

.footer {
    height: 118px;
}

.footer-bar {
    background-color: rgba(4, 40, 56, 0.6);
    border-radius: 12px;
    height: 38px;
    margin-bottom: 20px;
    color: #fff;
    font-size: 20px;
}

.footer-bar .car-status {
    width: 120px;
    padding: 3px 11px;
    background-color: #004c3f;
    border-radius: 10px;
    margin: 2px;
    height: 34px;
}

.footer-bar .car-info {
    width: 100%;
    padding-left: 130px;
    padding-right: 50px;
    text-align: center;
}

.footer-bar .car-setting {
    top: 0;
    right: 10px;
}

.car-info .title {
    padding: 0 10px;
    margin: 0;
    line-height: 38px;
}

.car-setting .btn {
    padding-right: 10px
}

.car-setting .btn i {
    color: #fff;
    font-size: 20px;
}

.app-body {
    background: #111;
    width: 100vw;
    height: 100vh;
}

@font-face {
    font-family: Outfit-Bold;
    src: url(/fonts/Outfit-Bold.ttf)
}

@font-face {
    font-family: Outfit-ExtraBold;
    src: url(/fonts/Outfit-ExtraBold.ttf)
}

@font-face {
    font-family: Outfit-Regular;
    src: url(/fonts/Outfit-Regular.ttf)
}

@font-face {
    font-family: Montserrat-Regular;
    src: url(/fonts/Montserrat-Regular.ttf)
}

@font-face {
    font-family: Montserrat-Medium;
    src: url(/fonts/Montserrat-Medium.ttf)
}

@font-face {
    font-family: Montserrat-Bold;
    src: url(/fonts/Montserrat-Bold.ttf)
}
c
@font-face {
    font-family: SF Pro;
    src: url(/fonts/SF-Pro.ttf)
}

@font-face {
    font-family: Poppins-Black-Italic;
    src: url(/fonts/Poppins-BlackItalic.ttf)
}

@font-face {
    font-family: Poppins-Bold;
    src: url(/fonts/Poppins-Bold.ttf)
}

@font-face {
    font-family: Poppins-SemiBold;
    src: url(/fonts/Poppins-SemiBold.ttf)
}

@font-face {
    font-family: Poppins-Regular;
    src: url(/fonts/Poppins-Regular.ttf)
}

@font-face {
    font-family: Poppins-ExtraBold;
    src: url(/fonts/Poppins-ExtraBold.ttf)
}

@font-face {
    font-family: Poppins-Medium;
    src: url(/fonts/Poppins-Medium.ttf)
}

@font-face {
    font-family: Taviraj-SemiBold-Italic;
    src: url(/fonts/Taviraj-SemiBoldItalic.ttf)
}

@font-face {
    font-family: Playfair-Display-Bold-Italic;
    src: url(/fonts/PlayfairDisplay-BoldItalic.ttf)
}

@font-face {
    font-family: Montserrat-SemiBold;
    src: url(/fonts/Montserrat-SemiBold.ttf)
}

@font-face {
    font-family: Outfit-SemiBold;
    src: url(/fonts/Outfit-SemiBold.ttf)
}

@font-face {
    font-family: Avenir-Heavy;
    src: url(/fonts/Avenir-Heavy.ttf)
}

.f-all {
    display: flex;
    justify-content: center;
    align-items: center;
}

.module-name {
    font-size: calc(.1771rem * 12);
    font-family: Montserrat-Bold;
    color: #fff;
    text-align: start;
    margin-top: calc(.375rem * 12);
    margin-bottom: calc(.1667rem * 12);
}

.title {
    width: calc(1.25rem * 12);
    font-size: calc(.1042rem * 12);
    font-family: Montserrat-SemiBold;
    color: #fff;
    line-height: calc(.1198rem * 12);
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin-top: calc(.0625rem * 12);
}

.swiper-slide-active .mask {
    display: none !important;
}

.swiper-slide-active .swiper-content {
    border-radius: calc(0.1042rem * 12) calc(0.1042rem * 12) 0 0 !important;
}

.swiper-slide-active .swiper-content:hover .hover-mask {
    /* visibility: visible; */
    opacity: 100;
}

.content-item:hover .title {
    display: none;
}

.n-tabs {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: background-color .3s cubic-bezier(.4, 0, .2, 1), border-color .3s cubic-bezier(.4, 0, .2, 1);
}

.n-tabs .n-tabs-nav {
    box-sizing: border-box;
    line-height: 1.5;
    display: flex;
    transition: border-color .3s cubic-bezier(.4, 0, .2, 1);
}

.n-tabs .n-tabs-nav-scroll-wrapper {
    flex: 1;
    position: relative;
    overflow: hidden;
}

.n-tabs .n-tabs-nav-scroll-wrapper::before,
.n-tabs .n-tabs-nav-scroll-wrapper::after {
    transition: box-shadow .3s cubic-bezier(.4, 0, .2, 1);
    pointer-events: none;
    content: "";
    position: absolute;
    z-index: 1;
}

.n-tabs.n-tabs--top .n-tabs-nav-scroll-wrapper::before,
.n-tabs.n-tabs--bottom .n-tabs-nav-scroll-wrapper::before {
    top: 0;
    bottom: 0;
    left: 0;
    width: 20px;
}

.n-tabs.n-tabs--top .n-tabs-nav-scroll-wrapper::after,
.n-tabs.n-tabs--bottom .n-tabs-nav-scroll-wrapper::after {
    top: 0;
    bottom: 0;
    right: 0;
    width: 20px;
}

.v-x-scroll {
    overflow: auto;
    scrollbar-width: none;
}

.n-tabs .n-tabs-nav-scroll-content {
    display: flex;
    position: relative;
    min-width: 100%;
    min-height: 100%;
    width: -moz-fit-content;
    width: fit-content;
    box-sizing: border-box;
}

.n-tabs .n-tabs-wrapper {
    display: inline-flex;
    flex-wrap: nowrap;
    position: relative;
}

.n-tabs .n-tabs-tab {
    cursor: pointer;
    white-space: nowrap;
    flex-wrap: nowrap;
    display: inline-flex;
    align-items: center;
    color: #A8A8A8;
    font-size: 16px;
    background-clip: padding-box;
    padding: 5px 0;
    transition: box-shadow .3s cubic-bezier(.4, 0, .2, 1), color .3s cubic-bezier(.4, 0, .2, 1), background-color .3s cubic-bezier(.4, 0, .2, 1), border-color .3s cubic-bezier(.4, 0, .2, 1);
}

.n-tabs.n-tabs--line-type .n-tabs-tab,
.n-tabs.n-tabs--bar-type .n-tabs-tab {
    font-weight: 700;
    box-sizing: border-box;
    vertical-align: bottom;
}

.n-tabs .n-tabs-tab.n-tabs-tab--active {
    color: #fff;
}

.n-tabs .n-tabs-tab .n-tabs-tab__label {
    display: flex;
    align-items: center;
    z-index: 1;
    font-size: calc(.0833rem * 12);
}

.n-tabs .n-tabs-tab-wrapper {
    display: flex;
    flex-wrap: nowrap;
    flex-shrink: 0;
    flex-grow: 0;
}

.n-tabs .n-tabs-tab-pad {
    box-sizing: border-box;
    width: 24px;
    flex-grow: 0;
    flex-shrink: 0;
}

.xgplayer {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    font-family: PingFang SC, Helvetica Neue, Helvetica, STHeiTi, Microsoft YaHei, WenQuanYi Micro Hei, sans-serif;
    font-size: 14px;
    font-weight: 400;
    background: #000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    cursor: pointer;
}

.content-item:hover .hover-container {
    display: block;
}

.swiper-slide-active .parent-active {
    display: flex !important;
}

.title {
    width: 100%;
    font-family: Montserrat-Bold;
    color: #fff;
    font-size: calc(.1042rem * 12);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: calc(.0229rem * 12);
}

#mse video {
    width: 100%;
    height: 100%;
}

.wrapper {
    width: 430px;
    background: #fff;
}

.wrapper header {
    color: #6990F2;
    font-size: 27px;
    font-weight: 600;
    text-align: center;
}

.wrapper form {
    height: 50px;
    display: flex;
    cursor: pointer;
    margin: 30px 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 5px;
    border: 2px dashed #6990F2;
}

form :where(i, p) {
    color: #6990F2;
}

form i {
    font-size: 50px;
}

form p {
    font-size: 16px;
}

section .row {
    margin-bottom: 10px;
    background: #E9F0FF;
    list-style: none;
    padding: 15px 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

section .row i {
    color: #6990F2;
    font-size: 30px;
}

section .details span {
    font-size: 14px;
}

.progress-area .row .content {
    width: 100%;
    margin-left: 15px;
}

.progress-area .details {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
    justify-content: space-between;
}

.progress-area .content .progress-bar {
    height: 6px;
    width: 100%;
    margin-bottom: 4px;
    background: #fff;
    border-radius: 30px;
}

.content .progress-bar .progress {
    height: 100%;
    width: 0%;
    background: #6990F2;
    border-radius: inherit;
}

.uploaded-area {
    max-height: 232px;
    overflow-y: scroll;
}

.uploaded-area.onprogress {
    max-height: 150px;
}

.uploaded-area::-webkit-scrollbar {
    width: 0px;
}

.uploaded-area .row .content {
    display: flex;
    align-items: center;
}

.uploaded-area .row .details {
    display: flex;
    margin-left: 15px;
    flex-direction: column;
}

.uploaded-area .row .details .size {
    color: #404040;
    font-size: 11px;
}

.uploaded-area i.fa-check {
    font-size: 16px;
}
